import React from "react";
import Layout from "components/layout";
import WpmlComp from "components/wpml/index";
import SEO from "components/seo";

function wpml() {
  const description = "Service Template";
  const title = "WPML Website Translation";
  const keywords = "";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/website-translation/wordpress/wpml"
      />
      <WpmlComp />
    </Layout>
  );
}

export default wpml;
